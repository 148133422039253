<template>
  <div class="row m-0 text-center text-md-start">
    <div class="col-12">
      <div class="row" v-for="step, key in theSteps" :key="key">
        <component
          :is="step.step"
          :stepNumber="step.stepNumber"
          :childProjects="childProjects"
          :currency="currency"
          :displayTotalAndCurrency="displayTotalAndCurrency"
          v-model:selectedChildProject="selectedChildProject"
          v-model:quantity="donation.quantity"
          :selectedChildProject="selectedChildProject"
          :theProject="theProject"
          v-model:donationTypes="donation.donationTypes"
          :errorMessage="errorMessage"
          @updateQuantity="setAmount"
          @updateAmount="updateAmount"
          :hasMultipleDonationTypes="hasMultipleDonationTypes"
        />
      </div>
    </div>
    <div class="col-12 p-0 mt-3">
      <div class="row m-0">
        <div class="col-12 col-md-auto mb-2">
          <Button color="green" width="100" btnText="Added" icon="arrow" class="mb-1" disabled="disabled" v-if="isAdded" size="big" />
          <Button color="green" width="100" btnText="Add to Cart" icon="arrow" @buttonClicked="addDonationToCart" size="big" class="mb-1" v-else :disabled="disabled">
            <IconCart color="gold" size="size16" />
          </Button>
        </div>
        <div class="col-12 col-md-auto mb-2">
          <Button color="red" width="100" btnText="Donate Now" icon="arrow" @buttonClicked="goDonate" size="big" class="mb-1" :disabled="disabled">
            <IconArrowForward color="white" size="size20" />
          </Button>
        </div>
      </div>
    </div>
    <transition name="comeIn">
      <AddCartPopup v-if="isAdded" @close="isAdded = false"/>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    SelectAppeal: defineAsyncComponent(() => import('../components/SelectAppeal.vue')),
    EnterQuantity: defineAsyncComponent(() => import('../components/EnterQuantity.vue')),
    SplitDonationTypes: defineAsyncComponent(() => import('../components/SplitDonationTypes.vue')),
    EnterDonationAmounts: defineAsyncComponent(() => import('../components/EnterDonationAmounts.vue')),
    PlaqueMessage: defineAsyncComponent(() => import('../components/PlaqueMessage.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    CurrencyField: defineAsyncComponent(() => import('@/components/CurrencyField.vue')),
    NumberField: defineAsyncComponent(() => import('@/components/NumberField.vue')),
    TextArea: defineAsyncComponent(() => import('@/components/TextArea.vue')),
    AddCartPopup: defineAsyncComponent(() => import('@/views/AddCartPopup.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconCart: defineAsyncComponent(() => import('@/components/icons/IconCart.vue'))
  },
  props: ['mergedProject', 'project', 'currency'],
  name: 'Project Donate',
  data () {
    return {
      donation: {
        quantity: 1,
        amount: 0.00,
        currency: this.currency,
        projectId: '',
        donationTypes: [],
        plaqueDescription: ''
      },
      selectedChildProject: null,
      isAdded: false,
      disabled: true,
      errorMessage: 'Ready to Donate'
    }
  },
  beforeMount () {
    this.setDonationTypes()
    this.donation.currency = this.currency
    if (this.theProject.fixed) {
      this.initialFixed()
    }
  },
  mounted () {
    if (this.childProjects && this.childProjects.length > 0) {
      this.selectedChildProject = this.childProjects[0]
    }
  },
  watch: {
    donation: {
      deep: true,
      handler (val) {
        this.checkAmount()
      }
    },
    selectedChildProject () {
      if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
        this.donation.projectId = this.selectedChildProject.adminProjectId
        this.setDonationTypes()
        if (this.selectedChildProject.fixed) {
          this.initialFixed()
        }
        this.checkAmount()
      }
    }
  },
  computed: {
    ...mapGetters([
      'adminProjects'
    ]),
    theSteps () {
      let steps = [
        {
          stepNumber: 1,
          step: 'EnterDonationAmounts'
        }
      ]
      if (this.childProjects && this.childProjects.length > 0) {
        if (this.selectedChildProject && this.selectedChildProject.fixed) {
          steps = [
            {
              stepNumber: 1,
              step: 'SelectAppeal'
            },
            {
              stepNumber: 2,
              step: 'EnterQuantity'
            },
            {
              stepNumber: 3,
              step: 'SplitDonationTypes'
            }
          ]
        } else {
          steps = [
            {
              stepNumber: 1,
              step: 'SelectAppeal'
            },
            {
              stepNumber: 2,
              step: 'EnterDonationAmounts'
            }
          ]
        }
      } else {
        if (this.mergedProject && this.mergedProject.fixed) {
          if (this.mergedProject.plaque) {
            steps = [
              {
                stepNumber: 1,
                step: 'EnterQuantity'
              },
              {
                stepNumber: 2,
                step: 'SplitDonationTypes'
              },
              {
                stepNumber: 3,
                step: 'PlaqueMessage'
              }
            ]
          } else {
            steps = [
              {
                stepNumber: 1,
                step: 'EnterQuantity'
              },
              {
                stepNumber: 2,
                step: 'SplitDonationTypes'
              }
            ]
          }
        } else {
          if (this.mergedProject.plaque) {
            steps = [
              {
                stepNumber: 1,
                step: 'EnterDonationAmounts'
              },
              {
                stepNumber: 2,
                step: 'PlaqueMessage'
              }
            ]
          } else {
            steps = [
              {
                stepNumber: 1,
                step: 'EnterDonationAmounts'
              }
            ]
          }
        }
      }
      return steps
    },
    theProject () {
      if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
        return this.selectedChildProject
      } else if (this.mergedProject && Object.keys(this.mergedProject).length > 0) {
        return this.mergedProject
      } else {
        return null
      }
    },
    childProjects () {
      const arr = this.adminProjects.filter(project => {
        if (project.parentProjectID !== this.project.adminProjectId) {
          return false
        }
        return true
      })
      arr.sort((a, b) => (a.description > b.description ? 1 : -1))
      return arr
    },
    emptyDonationTypes () {
      return this.donation.donationTypes.filter(type => {
        if (type.amount !== 0 && type.amount !== '0' && type.amount !== '' && type.amount !== null) {
          return false
        }
        return true
      })
    },
    nonEmptyDonationTypes () {
      return this.donation.donationTypes.filter(type => {
        if (type.amount === 0 || type.amount === '0' || type.amount === '' || type.amount === null) {
          return false
        }
        return true
      })
    },
    hasMultipleDonationTypes () {
      let ret = false
      if (this.theProject.projectDonationTypeMaps && this.theProject.projectDonationTypeMaps.length > 1) {
        ret = true
      }
      return ret
    },
    displayTotal () {
      return parseFloat(Math.round(this.donation.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    displayTotalAndCurrency () {
      return `${this.donation.currency} ${parseFloat(Math.round(this.donation.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`
    }
  },
  methods: {
    ...mapActions([
      'addToCart',
      'clearCart'
    ]),
    checkAmount () {
      if (this.theProject && this.theProject.fixed) {
        var total = 0
        this.nonEmptyDonationTypes.forEach(type => {
          total = (parseFloat(total) + parseFloat(type.amount)).toFixed(2)
        })
        if (total < this.donation.amount) {
          this.disabled = true
          this.errorMessage = 'Your donation needs another ' + this.currency + ' ' + (parseFloat(this.donation.amount) - parseFloat(total)).toFixed(2)
        } else if (total > this.donation.amount) {
          this.disabled = true
          this.errorMessage = 'Your donation is too big, remove ' + this.currency + ' ' + (parseFloat(total) - (parseFloat(this.donation.amount))).toFixed(2)
        } else {
          this.disabled = false
          this.errorMessage = 'Ready to Donate'
        }
      } else {
        if (this.donation.amount < 0.01) {
          this.disabled = true
          this.errorMessage = 'Donation needs to be greater than 0'
        } else {
          this.disabled = false
          this.errorMessage = 'Ready to Donate'
        }
      }
    },
    setDonationTypes () {
      if (this.theProject && this.theProject.projectID) {
        this.donation.donationTypes = []
        const adminProject = this.adminProjects.find(p1 => p1.projectID === this.theProject.projectID)
        const mergedProject = { ...this.theProject, ...adminProject }
        this.donation.projectId = mergedProject.projectID
        if (mergedProject && mergedProject.projectDonationTypeMaps && mergedProject.projectDonationTypeMaps.length > 0) {
          mergedProject.projectDonationTypeMaps.forEach(donationtype => {
            const typeObject = {}
            typeObject.type = donationtype
            this.donation.donationTypes.push(typeObject)
          })
        }
        this.donation.donationTypes.forEach(type => {
          type.amount = null
        })
      }
    },
    initialFixed () {
      this.setAmount()
      this.donation.donationTypes[0].amount = this.donation.amount.toString()
    },
    setAmount () {
      if (this.theProject && this.theProject.fixed) {
        this.donation.amount = this.donation.quantity * this.theProject.cost
        this.multiplyAmount()
      }
    },
    multiplyAmount () {
      if (this.theProject && this.theProject.fixed) {
        var total = 0
        this.nonEmptyDonationTypes.forEach(type => {
          total = (parseFloat(total) + parseFloat(type.amount)).toFixed(2)
        })
        const ratioTop = (parseFloat(this.donation.amount)).toFixed(2)
        const ratioBottom = ((parseFloat(total))).toFixed(2)
        if (this.donation.quantity && !this.disabled && ratioTop && ratioBottom && ((ratioTop !== 'NaN' && ratioTop !== 'Infinity' && ratioTop !== '0' && ratioTop !== 0) || (ratioBottom !== 'NaN' && ratioBottom !== 'Infinity' && ratioBottom !== '0' && ratioBottom !== 0))) {
          this.nonEmptyDonationTypes.forEach(type => {
            type.amount = (parseFloat(type.amount) * ratioTop / ratioBottom).toFixed(2)
          })
        }
      }
    },
    updateAmount (key) {
      console.log('updateAmount')
      if (this.theProject && this.theProject.fixed) {
        var total = 0
        this.nonEmptyDonationTypes.forEach(type => {
          total = (parseFloat(total) + parseFloat(type.amount)).toFixed(2)
        })
        if (this.emptyDonationTypes.length > 0 && parseFloat(total).toFixed(2) < this.donation.amount) {
          console.log('UPDATE IF')
          this.emptyDonationTypes[0].amount = (parseFloat(this.donation.amount) - parseFloat(total)).toFixed(2)
        }
      } else {
        let ret = 0
        this.donation.donationTypes.forEach(type => {
          ret += Number(type.amount)
        })
        this.donation.amount = ret
      }
    },
    goDonate () {
      this.addDonationToCart()
      this.$router.push('/checkout/0')
    },
    addDonationToCart () {
      this.addToCart(this.donation)
      this.isAdded = true
      this.donation = {
        quantity: 1,
        amount: 0.00,
        currency: this.currency,
        projectId: '',
        donationTypes: [],
        plaqueDescription: ''
      }
      this.setDonationTypes()
    }
  }
}
</script>

<style scoped>
.card_holder {
  border-radius: 15px;
}
.title {
  color: #3A3A3A;
  font-family: "quicksand_bold", Sans-serif;
  font-size: 17px;
  font-weight: 600;
}
.bg {
  background-color: #F4F4F4;
}
.bg:hover {
  background-color: #ffffff;
}
.bg_green {
  background-color: var(--green-color-dark);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.above_z {
  position: relative;
  z-index: 2;
}
.total {
  font-family: "quicksand", Sans-serif;
  font-size: 45px;
  font-weight: 400;
  color: var(--green-color);
  line-height: 1;
}
.error-text {
  font-size: 10px;
  color: red;
}
.border_end {
  border-right: none;
}
.btn {
  border-radius: 2rem;
  font-size: 15px;
  padding: 0 24px;
  line-height: 15px;
  border: 2px solid transparent;
  height: 39px;
}
.green {
    background-color: var(--green-color-dark);
    border: 2px solid var(--green-color-dark);
    color: var(--gold-color);
  }
  .green:hover {
    background-color: var(--green-color-light);
    color: var(--gold-color);
  }
.lightgrey_gold {
  background-color:  rgb(244, 244, 244);
  border: 0px;
  color: var(--green-color);
}
.lightgrey_gold:hover {
  background-color:  rgb(255, 218, 156);
  border: 0px;
  color: var(--green-color);
}
@media (min-width: 992px) {
  .border_end {
    border-right: 1px solid rgb(204, 204, 204);
  }
}
</style>
